import { differenceInDays, isAfter } from "date-fns"

import { SubscriptionStatus } from "src/components/Account/types"
import { isStoredFeatureEnabled } from "src/components/Flags/flagContextUtil"
import { FeatureFlag } from "src/constants/featureFlags"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { DUNNING_STAGE } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"

import { getAccessLogic } from "./accessLogic"

type TDunningParameter = {
  willBeCancelledAtDate?: Date
  daysUntilSuspension?: number
}

type TPaymentBannerData = {
  header: string
  text: string
}

type TTranslationData = {
  stage: DUNNING_STAGE
  alert: string
  action: string
  param: TDunningParameter
  actionSecondary?: string
}

export type TDunningData = TTranslationData & {
  isOwner: boolean
  banner: TPaymentBannerData
  isSuspended: boolean
  isCancelled: boolean
}

const dunningOwnerPaymentBannerMap: Record<DUNNING_STAGE, TPaymentBannerData> =
  {
    [DUNNING_STAGE.NO_DUNNING]: {
      header: "",
      text: "",
    },
    [DUNNING_STAGE.DUNNING_DAY_0]: {
      header: langKeys.dunning_banner_day_0_title,
      text: langKeys.dunning_banner_day_0_body_owner,
    },
    [DUNNING_STAGE.DUNNING_PRE_SUSPENDED]: {
      header: langKeys.dunning_banner_pre_suspended_title_owner,
      text: langKeys.dunning_banner_pre_suspended_body_owner,
    },
    [DUNNING_STAGE.DUNNING_SUSPENDED]: {
      header: langKeys.dunning_banner_suspended_title_owner,
      text: langKeys.dunning_banner_suspended_body_owner,
    },
    [DUNNING_STAGE.DUNNING_CANCELLED]: {
      header: langKeys.subscription_cancelled_banner_title,
      text: !isStoredFeatureEnabled(FeatureFlag.HOME_DELETE_REACTIVATION)
        ? langKeys.subscription_cancelled_banner_body_owner
        : langKeys.subscription_cancelled_banner_body_owner_reactivation,
    },
  }

const dunningMemberPaymentBannerMap: Record<DUNNING_STAGE, TPaymentBannerData> =
  {
    [DUNNING_STAGE.NO_DUNNING]: {
      header: "",
      text: "",
    },
    [DUNNING_STAGE.DUNNING_DAY_0]: {
      header: langKeys.dunning_banner_day_0_title,
      text: langKeys.dunning_banner_day_0_body_member,
    },
    [DUNNING_STAGE.DUNNING_PRE_SUSPENDED]: {
      header: langKeys.dunning_banner_pre_suspended_title_member,
      text: langKeys.dunning_banner_pre_suspended_body_member,
    },
    [DUNNING_STAGE.DUNNING_SUSPENDED]: {
      header: langKeys.dunning_banner_suspended_title_member,
      text: langKeys.dunning_banner_suspended_body_member,
    },
    [DUNNING_STAGE.DUNNING_CANCELLED]: {
      header: langKeys.subscription_cancelled_banner_title,
      text: langKeys.subscription_cancelled_banner_body_member,
    },
  }

export const dunningAlertMap: Record<DUNNING_STAGE, string> = {
  [DUNNING_STAGE.NO_DUNNING]: "",
  [DUNNING_STAGE.DUNNING_DAY_0]: langKeys.dunning_billing_alert_pre_suspended,
  [DUNNING_STAGE.DUNNING_PRE_SUSPENDED]:
    langKeys.dunning_billing_alert_pre_suspended,
  [DUNNING_STAGE.DUNNING_SUSPENDED]: langKeys.dunning_billing_alert_suspended,
  [DUNNING_STAGE.DUNNING_CANCELLED]: "",
}

export const dunningActionMap: Record<DUNNING_STAGE, string> = {
  [DUNNING_STAGE.NO_DUNNING]: "",
  [DUNNING_STAGE.DUNNING_DAY_0]: langKeys.payment_error_banner_button,
  [DUNNING_STAGE.DUNNING_PRE_SUSPENDED]: langKeys.payment_error_banner_button,
  [DUNNING_STAGE.DUNNING_SUSPENDED]: langKeys.payment_error_banner_button,
  [DUNNING_STAGE.DUNNING_CANCELLED]:
    langKeys.subscription_cancelled_banner_action,
}

export const dunningActionSecondaryMap: Record<DUNNING_STAGE, string> = {
  [DUNNING_STAGE.NO_DUNNING]: "",
  [DUNNING_STAGE.DUNNING_DAY_0]: "",
  [DUNNING_STAGE.DUNNING_PRE_SUSPENDED]: "",
  [DUNNING_STAGE.DUNNING_SUSPENDED]: "",
  [DUNNING_STAGE.DUNNING_CANCELLED]: isStoredFeatureEnabled(
    FeatureFlag.HOME_DELETE_REACTIVATION
  )
    ? langKeys.subscription_cancelled_banner_action_secondary
    : "",
}

export function getDunningData(
  willBeCancelledAt?: string,
  willBeSuspendedAt?: string,
  subscriptionStatus?: string
): TTranslationData {
  // GT-116: Handle manual pause state in a better way
  if (subscriptionStatus === SubscriptionStatus.PAUSED) {
    return {
      stage: DUNNING_STAGE.DUNNING_SUSPENDED,
      param: {},
      alert: dunningAlertMap[DUNNING_STAGE.DUNNING_SUSPENDED],
      action: dunningActionMap[DUNNING_STAGE.DUNNING_SUSPENDED],
    }
  }

  // Cancelled, checked via the subscription_status field.
  if (subscriptionStatus === SubscriptionStatus.CANCELLED) {
    return {
      stage: DUNNING_STAGE.DUNNING_CANCELLED,
      param: {},
      alert: dunningAlertMap[DUNNING_STAGE.DUNNING_CANCELLED],
      action: dunningActionMap[DUNNING_STAGE.DUNNING_CANCELLED],
      actionSecondary:
        dunningActionSecondaryMap[DUNNING_STAGE.DUNNING_CANCELLED],
    }
  }

  // Not cancelled, but no dunning parameters yet.
  if (!willBeCancelledAt || !willBeSuspendedAt) {
    return {
      stage: DUNNING_STAGE.NO_DUNNING,
      param: {},
      alert: "",
      action: "",
    }
  }

  const cancelledDate = new Date(willBeCancelledAt)
  const now = new Date()

  // Cancelled, checked via the cancellation date.
  if (isAfter(now, cancelledDate)) {
    return {
      stage: DUNNING_STAGE.DUNNING_CANCELLED,
      param: {},
      alert: dunningAlertMap[DUNNING_STAGE.DUNNING_CANCELLED],
      action: dunningActionMap[DUNNING_STAGE.DUNNING_CANCELLED],
    }
  }

  const suspendedDate = new Date(willBeSuspendedAt)
  // Suspended
  if (isAfter(now, suspendedDate)) {
    return {
      stage: DUNNING_STAGE.DUNNING_SUSPENDED,
      param: { willBeCancelledAtDate: cancelledDate },
      alert: dunningAlertMap[DUNNING_STAGE.DUNNING_SUSPENDED],
      action: dunningActionMap[DUNNING_STAGE.DUNNING_SUSPENDED],
    }
  }

  // About to get suspended
  if (differenceInDays(suspendedDate, now) < 7) {
    return {
      stage: DUNNING_STAGE.DUNNING_PRE_SUSPENDED,
      param: {
        daysUntilSuspension: differenceInDays(suspendedDate, new Date()),
      },
      alert: dunningAlertMap[DUNNING_STAGE.DUNNING_PRE_SUSPENDED],
      action: dunningActionMap[DUNNING_STAGE.DUNNING_PRE_SUSPENDED],
    }
  }

  // Payment failed
  return {
    stage: DUNNING_STAGE.DUNNING_DAY_0,
    param: {},
    alert: dunningAlertMap[DUNNING_STAGE.DUNNING_DAY_0],
    action: dunningActionMap[DUNNING_STAGE.DUNNING_DAY_0],
  }
}

// Hook to fetch the dunning banner info.
export function useDunning(): TDunningData {
  const { org } = useOrganization()
  const { _t } = useTranslate()

  const translationData = getDunningData(
    org.payment_status?.will_be_cancelled_at,
    org.payment_status?.will_be_suspended_at,
    org.current_subscription_status
  )
  const { hasOwnerAccess } = getAccessLogic({ role: org.user_role })
  const banner = hasOwnerAccess
    ? dunningOwnerPaymentBannerMap[translationData.stage]
    : dunningMemberPaymentBannerMap[translationData.stage]

  function tt(s: string, params?: TDunningParameter) {
    if (!s) return s
    return _t(s, params)
  }

  return {
    stage: translationData.stage,
    param: translationData.param,
    banner: {
      header: tt(banner.header, translationData.param),
      text: tt(banner.text, translationData.param),
    },
    isOwner: hasOwnerAccess,
    alert: tt(translationData.alert, translationData.param),
    action: tt(translationData.action),
    isSuspended: translationData.stage >= DUNNING_STAGE.DUNNING_SUSPENDED,
    isCancelled: translationData.stage >= DUNNING_STAGE.DUNNING_CANCELLED,
    actionSecondary:
      translationData.actionSecondary && tt(translationData.actionSecondary),
  }
}
