import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { paradiseOrderKeys } from "src/data/paradise/paradiseOrders/paradiseOrdersQueryCache"
import {
  TParadiseOrderResponse,
  TParadiseOrdersQueryParams,
  TParadiseOrdersResponse,
} from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchParadiseOrders({
  filter,
  options,
}: {
  filter?: TParadiseOrdersQueryParams
  options?: UseQueryOptions<
    TParadiseOrdersResponse,
    AxiosError,
    TParadiseOrdersResponse,
    ReturnType<typeof paradiseOrderKeys.orderList>
  >
}) {
  async function fetchParadiseOrders() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/ecommerce/orders`,
      { params: filter }
    )
    return response.data
  }

  return useQuery({
    queryKey: paradiseOrderKeys.orderList(filter),
    queryFn: fetchParadiseOrders,
    ...options,
  })
}

export function useFetchParadiseOrder({
  orderId,
  options,
}: {
  orderId: string
  options?: UseQueryOptions<
    TParadiseOrderResponse,
    AxiosError,
    TParadiseOrderResponse,
    ReturnType<typeof paradiseOrderKeys.order>
  >
}) {
  async function fetchParadiseOrder() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/ecommerce/orders/${orderId}`
    )
    return response.data
  }

  return useQuery({
    queryKey: paradiseOrderKeys.order(orderId),
    queryFn: fetchParadiseOrder,
    ...options,
  })
}
