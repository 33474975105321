import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"

import RentalUnitsEmptyStateImage from "./images/rental-units-empty-state.svg"

export function NoHomes() {
  const { t, langKeys } = useTranslate()

  return (
    <EmptyStateWithImage
      Image={RentalUnitsEmptyStateImage}
      title={t(langKeys.rental_units_empty_state_title)}
      description={t(langKeys.rental_units_empty_state_description)}
      content={
        <MButton appHref={Routes.CreateHome.location()}>
          {t(langKeys.create_home)}
        </MButton>
      }
    />
  )
}
