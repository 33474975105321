import { TParadiseOrdersQueryParams } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import {
  TSetSearchParamsProp,
  useSearchParams,
} from "src/router/useSearchParams"

// Prefix order params with `orders_` to avoid conflicts with other filters.
// Allow null values to be compatible with `useSearchParams`.
export type TParadiseOrdersSearchFilter = {
  [K in keyof Omit<TParadiseOrdersQueryParams, "limit"> as `orders_${K}`]:
    | TParadiseOrdersQueryParams[K]
    | null
}

export function useParadiseOrdersFilter(): {
  filter: TParadiseOrdersSearchFilter
  setFilter: TSetSearchParamsProp<TParadiseOrdersSearchFilter>
  setOffset: (offset: number) => void
  offset: number
} {
  const {
    searchParams: filter,
    setSearchParams: setFilter,
    setSearchParamsAll,
  } = useSearchParams({
    keys: [
      {
        key: "orders_order_id",
        type: "string",
      },
      {
        key: "orders_user_id",
        type: "string",
      },
      {
        key: "orders_offset",
        type: "number",
      },
    ],
  })

  return {
    filter,
    setFilter: (key, value) => {
      if (key === "orders_offset") {
        return
      }

      // Make sure we reset paging on filter
      setSearchParamsAll({
        [key]: value,
        orders_offset: 0,
      })
    },
    setOffset: (offset: number) => {
      setFilter("orders_offset", offset)
    },
    offset: filter.orders_offset || 0,
  }
}
