import { ParadiseOrdersFilters } from "src/components/Paradise/ParadiseOrders/ParadiseOrdersFilter"
import { OrderStatusBadge } from "src/components/Paradise/ParadiseOrders/ParadiseOrderStatusBadge"
import { ParadiseOrderTrackingReferenceBadge } from "src/components/Paradise/ParadiseOrders/ParadiseOrderTrackingReferenceBadge"
import { useParadiseOrdersFilter } from "src/components/Paradise/ParadiseOrders/useParadiseOrdersFilter"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  Capitalize,
  ParadiseLayout,
  ParadiseTitle,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import {
  TableColumn,
  useTableColumns,
} from "src/components/Paradise/useTableColumns/useTableColumns"
import { chargebeeInvoiceUrl } from "src/constants/hrefs"
import { countryCodeToCountry } from "src/data/countries/countryUtil"
import { useFetchParadiseOrders } from "src/data/paradise/paradiseOrders/paradiseOrderQueries"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

export function ParadiseOrders() {
  const { navigate } = useRouter()
  const { filter, setFilter, offset, setOffset } = useParadiseOrdersFilter()

  const limit = 50

  const fetchParadiseOrders = useFetchParadiseOrders({
    filter: {
      limit,
      offset,
      user_id: filter.orders_user_id || undefined,
      order_id: filter.orders_order_id || undefined,
    },
    options: {
      keepPreviousData: true,
    },
  })

  const {
    headerElements,
    interactiveColumns,
    interactiveVisibleColumns,
    rows,
    setColumnVisibility,
    templateColumns,
  } = useTableColumns({
    columns: tableColumns,
    data: fetchParadiseOrders.data?.orders,
    options: { localStorageKey: "minut.paradise.orders.table" },
  })

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Orders
            <MBadge color="info">
              Total:
              {fetchParadiseOrders.isSuccess &&
                fetchParadiseOrders.data?.paging.total_count}
            </MBadge>
          </ParadiseTitle>
        }
      />

      <TopWrapper>
        <ParadiseOrdersFilters filter={filter} setFilter={setFilter} />

        <DropdownMultiSelect
          label="Columns"
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          options={interactiveColumns}
          onChange={({ checked, option }) => {
            setColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>

      <ParadiseTable
        rows={rows ?? []}
        templateColumns={templateColumns}
        header={headerElements}
        onRowClick={(index) => {
          const order = fetchParadiseOrders.data?.orders[index]
          if (order) {
            navigate(Routes.ParadiseOrder.location(order.id))
          }
        }}
        error={{
          hasError: fetchParadiseOrders.isError,
          title: fetchParadiseOrders.error?.message,
        }}
      />

      <ParadisePager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchParadiseOrders.data?.paging.total_count}
      />
    </ParadiseLayout>
  )
}

const tableColumns: TableColumn<TParadiseOrder>[] = [
  {
    label: "Order number",
    value: "order_number",
    columnWidth: "min-content",
    render: (data) => (
      <div>
        <InternalLink to={Routes.ParadiseOrder.location(data.id).pathname}>
          {data.id}
        </InternalLink>
      </div>
    ),
  },
  {
    label: "Date",
    value: "date",
    disabled: true,
    columnWidth: "min-content",
    render: (data) => (
      <div>{formatDate({ date: data.created_at, timezone: "utc" })}</div>
    ),
  },

  {
    label: "User",
    value: "user_id",
    disabled: true,
    columnWidth: "min-content",
    render: (data) => (
      <div>
        {data.user_id && (
          <InternalLink
            to={Routes.ParadiseUser.location(data.user_id).pathname}
          >
            {data.user_id}
          </InternalLink>
        )}
      </div>
    ),
  },
  {
    label: "Total",
    value: "total",
    columnWidth: "min-content",
    render: (data) => <div>{data.total_price.formatted_amount}</div>,
  },
  {
    label: "Status",
    value: "status",
    columnWidth: "min-content",
    render: (data) => (
      <div>
        <OrderStatusBadge status={data.status} />
      </div>
    ),
  },
  {
    label: "Items",
    value: "items",
    disabled: true,
    columnWidth: "min-content",
    render: (data) => {
      const itemsCount = data.line_items.length
      return itemsCount === 1 ? (
        <div>{itemsCount} item</div>
      ) : (
        <div>{itemsCount} items</div>
      )
    },
  },
  {
    label: "Ship to",
    value: "ship_to",
    disabled: true,
    columnWidth: "min-content",
    render: (data) => (
      <Capitalize>
        {countryCodeToCountry(data.shipping_address.country)}
      </Capitalize>
    ),
  },
  {
    label: "Tracking number",
    value: "tracking_reference",
    disabled: true,
    columnWidth: "min-content",
    render: (data) => (
      <ParadiseOrderTrackingReferenceBadge
        tracking_reference={data.tracking_reference}
      />
    ),
  },
  {
    label: "Invoice",
    value: "invoice",
    disabled: true,
    columnWidth: "min-content",
    render: (data) =>
      data.invoice_id && (
        <div>
          <ExternalLink href={chargebeeInvoiceUrl(data.invoice_id)}>
            {data.invoice_id}
          </ExternalLink>
        </div>
      ),
  },
  {
    label: "Source",
    value: "source",
    disabled: true,
    columnWidth: "min-content",
    render: (data) => <Capitalize>{data.source}</Capitalize>,
  },
]
